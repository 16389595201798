import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import exclusive from "./assets/feature-exclusive.png";
import reward from "./assets/feature-rewards.png";
import govern from "./assets/feature-self govern.png";
import logo from "./assets/logo-MurzFeed.png";
import catspill from "./assets/plzwait4.png";
import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { TextField } from "@mui/material";
import { Card } from "@mui/material";
import { CardContent } from "@mui/material";
import { Button } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import ScrollTrigger from "mui-scroll-trigger";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { db } from "./firebase-config";
import { collection, addDoc, Timestamp } from "firebase/firestore";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">MurzFeed</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

const footers = [
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];

function Main(props) {
  const [newFeedback, setNewFeedback] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const feedbackCollectionRef = collection(db, "feedback");
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [openTc, setOpenTc] = React.useState(false);
  const [scrollTc, setScrollTc] = React.useState("paper");

  const createFeed = async (event) => {
    event.preventDefault();

    await addDoc(feedbackCollectionRef, {
      email: newEmail,
      feedbackDetails: newFeedback,
      tsCreated: Timestamp.now(),
    })
      .then(() => {
        alert(
          "Thank you for your feedback. Stay tune on our social media channels for the latest update!!"
        );
      })
      .catch((error) => {
        alert(error.message);
      });
    setNewFeedback("");
    setNewEmail("");
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClickOpenTc = (scrollType) => () => {
    setOpenTc(true);
    setScrollTc(scrollType);
  };

  const handleCloseTc = () => {
    setOpenTc(false);
  };

  const descriptionElementRefTc = React.useRef(null);
  React.useEffect(() => {
    if (openTc) {
      const { current: descriptionElementTc } = descriptionElementRefTc;
      if (descriptionElementTc !== null) {
        descriptionElementTc.focus();
      }
    }
  }, [openTc]);

  const calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`${year}-04-22`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      let dayTemp = Math.floor(difference / (1000 * 60 * 60 * 24));
      let hourTemp = Math.floor((difference / (1000 * 60 * 60)) % 24);
      let minuteTemp = Math.floor((difference / 1000 / 60) % 60);
      let secondTemp = Math.floor((difference / 1000) % 60);
      let day = "00";
      let hour = "00";
      let minute = "00";
      let sec = "00";

      if (dayTemp.toString().length < 2) {
        day = "0" + dayTemp.toString();
      } else {
        day = dayTemp.toString();
      }

      if (hourTemp.toString().length < 2) {
        hour = "0" + hourTemp.toString();
      } else {
        hour = hourTemp.toString();
      }

      if (minuteTemp.toString().length < 2) {
        minute = "0" + minuteTemp.toString();
      } else {
        minute = minuteTemp.toString();
      }

      if (secondTemp.toString().length < 2) {
        sec = "0" + secondTemp.toString();
      } else {
        sec = secondTemp.toString();
      }

      timeLeft = {
        Days: day,
        Hours: hour,
        Minutes: minute,
        Seconds: sec,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(<span>{timeLeft[interval]}</span>);
  });

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          color="default"
          elevation={1}
          sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
          style={{ backgroundColor: "#fafafa" }}
        >
          <Container>
            <Toolbar
              sx={{ flexWrap: "wrap" }}
              maxWidth="lg"
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <Box
                component="img"
                m="auto"
                src={logo}
                sx={{ maxWidth: 200 }}
              ></Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      {/* Hero unit */}
      <Container
        maxWidth="false"
        component="main"
        style={{ backgroundColor: "#eef0f1", paddingTop: "10%" }}
        disableGutters
      >
        <Container maxWidth="lg" component="main">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={6} wrap="wrap">
              <Box
                sx={{
                  height: "100%",
                  paddingTop: "40px",
                  paddingBottom: "20px",
                }}
              >
                <Typography
                  component="h3"
                  variant="h3"
                  align="left"
                  color="text.primary"
                  style={{ fontWeight: "700" }}
                >
                  PSPS..
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  color="text.secondary"
                  component="p"
                  style={{ fontSize: "18px" }}
                  gutterBottom
                >
                  Be a part of the biggest exclusive and self governing
                  c(at)ommunity online. Share your stories, rants and teas with
                  fellow MurzFams! For more info follow our social media
                  channels.
                </Typography>
                <Link
                  href="https://instagram.com/ecommurz?utm_medium=copy_link"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <InstagramIcon
                    fontSize="large"
                    sx={{ color: "#E4405F", "&:hover": { color: "#f59e2c" } }}
                  />
                </Link>
                <Link
                  href="https://www.youtube.com/channel/UCUE8pPnv-YbiZqEWxfVzh3g"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <YouTubeIcon
                    fontSize="large"
                    sx={{ color: "#CD201F", "&:hover": { color: "#f59e2c" } }}
                  />
                </Link>
                <Container
                  component="main"
                  alignItems="left"
                  style={{ paddingLeft: 0 }}
                >
                  <Typography
                    component="h4"
                    variant="h5"
                    align="left"
                    color="#f59e2c"
                    style={{
                      fontWeight: "500",
                      paddingTop: "15px",
                      paddingBottom: "8px",
                    }}
                    gutterBottom
                  >
                    V.0.9 Launch In...
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      border: 2,
                      justifyContent: "space-evenly",
                      borderColor: "text.primary",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        pt={3}
                        align="center"
                        component="p"
                        variant="h4"
                      >
                        {timerComponents[0]}{" "}
                      </Typography>
                      <Typography
                        pb={3}
                        align="center"
                        component="p"
                        variant="subtitle2"
                      >
                        Days
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        pt={3}
                        pb={3}
                        align="center"
                        component="p"
                        variant="h4"
                      >
                        :
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        pt={3}
                        align="center"
                        component="p"
                        variant="h4"
                      >
                        {timerComponents[1]}{" "}
                      </Typography>
                      <Typography
                        pb={3}
                        align="center"
                        component="p"
                        variant="subtitle2"
                      >
                        Hours
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        pt={3}
                        pb={3}
                        align="center"
                        component="p"
                        variant="h4"
                      >
                        :
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        pt={3}
                        align="center"
                        component="p"
                        variant="h4"
                      >
                        {timerComponents[2]}{" "}
                      </Typography>
                      <Typography
                        pb={3}
                        align="center"
                        component="p"
                        variant="subtitle2"
                      >
                        Minutes
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        pt={3}
                        pb={3}
                        align="center"
                        direction="column"
                        justifyContent="center"
                        component="p"
                        variant="h4"
                      >
                        :
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 1,
                      }}
                    >
                      <Typography
                        pt={3}
                        align="center"
                        component="p"
                        variant="h4"
                      >
                        {timerComponents[3]}{" "}
                      </Typography>
                      <Typography
                        pb={3}
                        align="center"
                        component="p"
                        variant="subtitle2"
                      >
                        Seconds
                      </Typography>
                    </Box>
                  </Box>
                </Container>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{ marginTop: "50px", height: "60%", width: "100%" }}
                component="img"
                src={catspill}
              ></Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography align="center" variant="h4">
          {"Features"}
        </Typography>
        <Box component="section" sx={{ display: "flex", overflow: "hidden" }}>
          <Container
            sx={{ mt: 5, mb: 10, display: "flex", position: "relative" }}
          >
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <Box>
                  <Box
                    sx={{
                      height: 100,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img src={reward} alt="wonder" height="100" />
                  </Box>
                  <Typography align="center" variant="h6" sx={{ my: 5 }}>
                    Get Your Voice Heard
                  </Typography>
                  <Typography align="center" variant="body1">
                    {
                      "With 300 DMs per day, Murz paws can't reply to all on IG. But here, the community are here for you and top stories will get to murz socmed channels."
                    }
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <Box
                    sx={{
                      height: 100,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img src={exclusive} alt="wonder" height="100" />
                  </Box>

                  <Typography align="center" variant="h6" sx={{ my: 5 }}>
                    Exclusive Community
                  </Typography>
                  <Typography align="center" variant="body1">
                    {
                      "Only cool cats allowed LOL jk, only if u get the invitation to meet and interact with fellow tech-workers and tech-enthusiast."
                    }
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <Box
                    sx={{
                      height: 100,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img src={govern} alt="wonder" height="100" />
                  </Box>
                  <Typography align="center" variant="h6" sx={{ my: 5 }}>
                    Govern by the Community
                  </Typography>
                  <Typography align="center" variant="body1">
                    "MurzFams Together Strong" as you all have the ability to
                    moderate and decide which content should stay posted on
                    MurzFeed.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
      {/* End hero unit */}
      <Container
        disableGutters
        sx={{ pt: 8, pb: 6 }}
        maxWidth="false"
        component="main"
        style={{ backgroundColor: "#eef0f1" }}
        disableGutters
      >
        <ScrollTrigger
          onScroll="grow"
          ignoreDirection
          onScroll="show"
          threshold={100}
        >
          <Typography align="center" variant="h4" pb={3}>
            {"Roadmap"}
          </Typography>
        </ScrollTrigger>
        <Timeline position="alternate">
          <ScrollTrigger
            onScroll="zoom"
            ignoreDirection
            onScroll="show"
            threshold={100}
          >
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                April 2022
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="secondary"></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  V.0.9
                </Typography>
                <Typography>Beta Release</Typography>
              </TimelineContent>
            </TimelineItem>
          </ScrollTrigger>
          <ScrollTrigger
            onScroll="zoom"
            ignoreDirection
            onScroll="show"
            threshold={100}
          >
            <TimelineItem>
              <TimelineOppositeContent sx={{ m: "auto 0" }} variant="body2">
                May 2022
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  V.1.0
                </Typography>
                <Typography>Exclusivity Features</Typography>
              </TimelineContent>
            </TimelineItem>
          </ScrollTrigger>
          <ScrollTrigger
            onScroll="zoom"
            ignoreDirection
            onScroll="show"
            threshold={100}
          >
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                Agustust 2022
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  V.2.0
                </Typography>
                <Typography>Governance Features</Typography>
              </TimelineContent>
            </TimelineItem>
          </ScrollTrigger>
          <ScrollTrigger
            onScroll="zoom"
            ignoreDirection
            onScroll="show"
            threshold={100}
          >
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
                January 2023
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot></TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  V.3.0
                </Typography>
                <Typography>MurzFam Requested Features</Typography>
              </TimelineContent>
            </TimelineItem>
          </ScrollTrigger>
          {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <TimelineDot color="secondary">
            <RepeatIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            V.3.0
          </Typography>
          <Typography>Reward Feature Development</Typography>
        </TimelineContent>
      </TimelineItem>*/}
        </Timeline>
      </Container>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography align="center" variant="h4" pb={3}>
          Feedback
        </Typography>
        <Grid>
          <Card
            style={{ maxWidth: 550, padding: "20px 5px", margin: "0 auto" }}
          >
            <CardContent>
              <Typography
                variant="body2"
                align="left"
                color="text.secondary"
                component="p"
                style={{ fontSize: "18px" }}
                gutterBottom
              >
                Your feedback and recommendations are very much appreciated. We
                will try to integrate it into Murzfeed. After all, this project
                is built just for MurzFam.
              </Typography>
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      placeholder="Enter email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      required
                      value={newEmail}
                      onChange={(event) => {
                        setNewEmail(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      multiline
                      rows={4}
                      placeholder="Type your message here"
                      variant="outlined"
                      fullWidth
                      required
                      value={newFeedback}
                      onChange={(event) => {
                        setNewFeedback(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={createFeed}
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Container>
      {/* Footer */}
      <Container
        maxWidth="lg"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Box m="auto" justifyContent="center" display="flex">
          <Button
            onClick={handleClickOpenTc("paper")}
            sx={{ color: "#808080" }}
          >
            Terms and Conditions{" "}
          </Button>
          <Button onClick={handleClickOpen("paper")} sx={{ color: "#808080" }}>
            Privacy Policy{" "}
          </Button>
        </Box>
        <div>
          <Dialog
            open={openTc}
            onClose={handleCloseTc}
            scroll={scrollTc}
            aria-labelledby="scroll-dialog-title-tc"
            aria-describedby="scroll-dialog-description-tc"
          >
            <DialogTitle id="scroll-dialog-title-tc">
              Terms and Conditions
            </DialogTitle>
            <DialogContent dividers={scrollTc === "paper"}>
              <DialogContent
                id="scroll-dialog-description-tc"
                ref={descriptionElementRefTc}
                tabIndex={-1}
              >
                <Typography variant="body1" color="initial">
                  Welcome to MurzFeed!
                </Typography>
                <Typography variant="body1" color="initial">
                  These terms and conditions outline the rules and regulations
                  for the use of MurzFeed.
                </Typography>
                <Typography variant="body1" color="initial">
                  By accessing this website, you are accepting these terms and
                  conditions. Do not continue to use MurzFeed if you do not
                  agree to take all of the terms and conditions stated on this
                  page. The following terminology applies to these Terms and
                  Conditions, Privacy Statement and Disclaimer Notice and all
                  Agreements: “MurzFam”, “You” and “Your” refers to you, the
                  person log on this website and compliant to these terms and
                  conditions. “Ourselves”, “We”, “Our” and “Us”, refers to the
                  admins of MurzFeed. “Party”, “Parties”, or “Us”, refers to
                  both of us.
                </Typography>

                <Typography variant="h5" color="initial">
                  Cookies
                </Typography>
                <Typography variant="body1" color="initial">
                  We employ the use of cookies. By accessing MurzFeed, you
                  agreed to use cookies in agreement with the MurzFeed's Privacy
                  Policy. Most interactive websites use cookies to let us
                  retrieve the user's details for each visit. Cookies are used
                  by our website to enable the functionality of certain areas to
                  make it easier for people visiting our website. Some of our
                  affiliate/advertising partners may also use cookies.
                </Typography>

                <Typography variant="h5" color="initial">
                  License
                </Typography>
                <Typography variant="body1" color="initial">
                  Unless otherwise stated, MurzFeed and/or its licensors own the
                  intellectual property rights for all material on MurzFeed
                  intellectual property rights are reserved. You may access this
                  from MurzFeed for your own personal use subjected to
                  restrictions set in these terms and conditions. You must not;
                  Republish material from MurzFeed, Sell, rent or sub-license
                  material from MurzFeed, Reproduce, duplicate or copy material
                  from MurzFeed. This Agreement shall begin on the date hereof.
                  Parts of this website offer an opportunity for users to post
                  and exchange opinions and information in certain areas of the
                  website. MurzFeed does not filter, edit, publish or review
                  Comments prior to their presence on the website. Comments do
                  not reflect the views and opinions of MurzFeed, its agents
                  and/or affiliates. Comments reflect the views and opinions of
                  the person who post their views and opinions. To the extent
                  permitted by applicable laws, MurzFeed shall not be liable for
                  the Comments or for any liability, damages or expenses caused
                  and/or suffered as a result of any use of and/or posting of
                  and/or appearance of the Comments on this website. MurzFeed
                  reserves the right to monitor all Comments and to remove any
                  Comments which can be considered inappropriate, offensive or
                  causes breach of these Terms and Conditions. You warrant and
                  represent that; You are entitled to post the Comments on our
                  website and have all necessary licenses and consents to do so;
                  The Comments do not invade any intellectual property right,
                  including without limitation copyright, patent or trademark of
                  any third party; The Comments do not contain any defamatory,
                  libellous, offensive, indecent or otherwise unlawful material
                  which is an invasion of privacy The Comments will not be used
                  to solicit or promote business or custom or present commercial
                  activities or unlawful activity. You hereby grant MurzFeed a
                  non-exclusive license to use, reproduce, edit and authorize
                  others to use, reproduce and edit any of your Comments in any
                  and all forms, formats or media.
                </Typography>

                <Typography variant="h5" color="initial">
                  Hyperlinking to our Content
                </Typography>

                <Typography variant="body1" color="initial">
                  The following organizations may link to our website without
                  prior written approval; Government agencies, Search engines,
                  News organizations, and Online directory distributors may link
                  to our website in the same manner as they hyperlink to the
                  Websites of other listed businesses. These organisations may
                  link to our home page, to publications or to other website
                  information so long as the link: (a) is not in any way
                  deceptive; (b) does not falsely imply sponsorship, endorsement
                  or approval of the linking party and its products and/or
                  services; and (c) fits within the context of the linking
                  party's site. No use of MurzFeed's logo or other artwork will
                  be allowed for linking without a trademark license agreement.
                </Typography>

                <Typography variant="h5" color="initial">
                  Content Liability
                </Typography>

                <Typography variant="body1" color="initial">
                  We shall not be hold responsible for any content that appears
                  on your Website. You agree to protect and defend us against
                  all claims that is rising on your Website. No link(s) should
                  appear on any Website that may be interpreted as libellous,
                  obscene or criminal, or which infringes, otherwise violates,
                  or advocates the infringement or other violation of, any
                  third-party rights.
                </Typography>

                <Typography variant="h5" color="initial">
                  Reservation of Rights
                </Typography>

                <Typography variant="body1" color="initial">
                  We reserve the right to request that you remove all links or
                  any particular link to our website. You approve to immediately
                  remove all links to our Website upon request. We also reserve
                  the right to amend these terms and conditions and its linking
                  policy at any time. By continuously linking to our website,
                  you agree to be bound to and follow these linking terms and
                  conditions.
                </Typography>

                <Typography variant="h5" color="initial">
                  Removal of links from our website
                </Typography>

                <Typography variant="body1" color="initial">
                  If you find any link on our website that is offensive for any
                  reason, you are free to contact and inform us any moment. We
                  will consider requests to remove links but we are not
                  obligated to or so or to respond to you directly. We do not
                  ensure that the information on this website is correct, we do
                  not warrant its completeness or accuracy; nor do we promise to
                  ensure that the website remains available or that the material
                  on the website is kept up to date.
                </Typography>

                <Typography variant="h5" color="initial">
                  Disclaimer
                </Typography>

                <Typography variant="body1" color="initial">
                  To the maximum extent permitted by applicable law, we exclude
                  all representations, warranties and conditions relating to our
                  website and the use of this website. Nothing in this
                  disclaimer will: limit or exclude our or your liability for
                  death or personal injury; limit or exclude our or your
                  liability for fraud or fraudulent misrepresentation; limit any
                  of our or your liabilities in any way that is not permitted
                  under applicable law; or exclude any of our or your
                  liabilities that may not be excluded under applicable law. The
                  limitations and prohibitions of liability set in this Section
                  and elsewhere in this disclaimer: (a) are subject to the
                  preceding paragraph; and (b) govern all liabilities arising
                  under the disclaimer, including liabilities arising in
                  contract, in tort and for breach of statutory duty. All the
                  terms and conditions herein are governed in accordance with
                  and subject to, prevailing laws of Republic of Indonesia. As
                  long as the website and the information and services on the
                  website are provided free of charge, we will not be liable for
                  any loss or damage of any nature. Updated as per April 2022
                </Typography>
              </DialogContent>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTc}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">Privacy Policy</DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContent
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <Typography variant="body1" color="initial">
                  This privacy policy ("policy") will help you understand how
                  MurzFeed ("us", "we", "our") uses and protects the data you
                  provide to us when you visit and use MurzFeed ("website",
                  "service").
                </Typography>
                <Typography variant="body1" color="initial">
                  We reserve the right to change this policy at any given time,
                  of which you will be promptly updated. If you want to make
                  sure that you are up to date with the latest changes, we
                  advise you to frequently visit this page.
                </Typography>
                <Typography variant="h5" color="initial">
                  What User Data We Collect
                </Typography>
                <Typography>
                  When you visit the website, we may collect the following data;
                  Your contact information and email address, Your contact
                  information and email address, Other information such as
                  interests and preferences, Data profile regarding your online
                  behavior on our website.
                </Typography>
                <Typography variant="h5" color="initial">
                  Why We Collect Your Data
                </Typography>
                <Typography>
                  We are collecting your data for several reasons; To better
                  understand your needs, To improve our services and products,
                  To contact you to fill out surveys and participate in other
                  types of market research, To customize our website according
                  to your online behavior and personal preferences.
                </Typography>
                <Typography variant="h5" color="initial">
                  Safeguarding and Securing the Data
                </Typography>
                <Typography>
                  MurzFeed is committed to securing your data and keeping it
                  confidential.
                </Typography>
                <Typography variant="h5" color="initial">
                  Our Cookie Policy
                </Typography>
                <Typography>
                  Once you agree to allow our website to use cookies, you also
                  agree to use the data it collects regarding your online
                  behavior (analyze web traffic, web pages you spend the most
                  time on, and websites you visit). The data we collect by using
                  cookies is used to customize our website to your needs. After
                  we use the data for statistical analysis, the data is
                  completely removed from our systems. Please note that cookies
                  don't allow us to gain control of your computer in any way.
                  They are strictly used to monitor which pages you find useful
                  and which you do not so that we can provide a better
                  experience for you. If you want to disable cookies, you can do
                  it by accessing the settings of your internet browser.
                </Typography>
                <Typography variant="h5" color="initial">
                  Links to Other Websites
                </Typography>
                <Typography>
                  Our website contains links that lead to other websites. If you
                  click on these links, MurzFeed is not held responsible for
                  your data and privacy protection. Visiting those websites is
                  not governed by this privacy policy agreement. Make sure to
                  read the privacy policy documentation of the website you go to
                  from our website.
                </Typography>
                <Typography variant="h5" color="initial">
                  Restricting the Collection of your Personal Data
                </Typography>
                <Typography>
                  At some point, you might wish to restrict the use and
                  collection of your personal data. You can achieve this by
                  doing the following: When you are filling the forms on the
                  website, make sure to check if there is a box which you can
                  leave unchecked, if you don't want to disclose your personal
                  information. If you have already agreed to share your
                  information with us, feel free to contact us via email at
                  hellomurz@gmail.com and we will be more than happy to change
                  this for you. MurzFeed will not lease, sell or distribute your
                  personal information to any third parties, unless we have your
                  permission. We might do so if the law forces us. Your personal
                  information will be used when we need to send you promotional
                  materials if you agree to this privacy policy. All the terms
                  and conditions herein are governed in accordance with and
                  subject to, prevailing laws of Republic of Indonesia Updated
                  as per April 2022.
                </Typography>
              </DialogContent>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>

        <Copyright sx={{ mt: 5 }} />
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}

export default function mainContent() {
  return <Main />;
}
