
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore} from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAWSjTI2f-xNE1I8GUUIcOtEZBVA6DKBlM",
  authDomain: "mfeed-c43b1.firebaseapp.com",
  projectId: "mfeed-c43b1",
  storageBucket: "mfeed-c43b1.appspot.com",
  messagingSenderId: "166913386109",
  appId: "1:166913386109:web:64881f1f4eff68101ef314",
  measurementId: "G-M011L63N9L"
    // apiKey: `${process.env.REACT_APP_API_KEY}`,
    // authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
    // projectId: "murzfeedprod",
    // storageBucket: "murzfeedprod.appspot.com",
    // messagingSenderId: "581400209689",
    // appId: "1:581400209689:web:73201d4445bbbec98ffd88",
    // measurementId: "G-HW5CNL5E73"
    //s
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  export const db = getFirestore(app);
